import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Button, OverlaidPortal } from '@class101/ui';
import styled from 'styled-components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "overlaidportal"
    }}>{`OverlaidPortal`}</h1>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={OverlaidPortal} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'() => {\n  const [opened, setOpened] = React.useState(false)\n  const openOverlay = () => setOpened(true)\n  const closeOverlay = () => setOpened(false)\n  return (\n    <React.Fragment>\n      <Button onClick={openOverlay}>Open Overlay</Button>\n      <OverlaidPortal opened={opened} onClose={closeOverlay}>\n        Overlay Children\n      </OverlaidPortal>\n    </React.Fragment>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      OverlaidPortal,
      styled,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [opened, setOpened] = React.useState(false);

        const openOverlay = () => setOpened(true);

        const closeOverlay = () => setOpened(false);

        return <React.Fragment>
        <Button onClick={openOverlay} mdxType="Button">Open Overlay</Button>
        <OverlaidPortal opened={opened} onClose={closeOverlay} mdxType="OverlaidPortal">
          Overlay Children
        </OverlaidPortal>
      </React.Fragment>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      